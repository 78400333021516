<template>
  <main class="pb-6 bg-white">
    <div class="relative red-gray-800">
      <div class="absolute inset-0">
        <img class="hidden object-cover object-bottom w-full h-full lg:block"
             src="../assets/montage_header.webp" width="1280" height="675" alt="montage_en_onderhoud">

        <img class="block object-cover object-bottom w-full h-full lg:hidden"
             src="../assets/montage_header_small.webp" width="320" height="196" alt="montage_en_onderhoud">

        <div class="absolute inset-0 bg-gray-800" style="mix-blend-mode: hard-light;" aria-hidden="true"></div>
      </div>
      <div class="relative py-24 px-4 mx-auto max-w-7xl sm:py-32 sm:px-6 lg:px-8">
        <h2 class="text-4xl font-medium tracking-tight text-white sm:text-5xl lg:text-6xl">Montage en onderhoud</h2>
        <p class="mt-6 max-w-3xl text-base text-gray-300">
          Bij JC Las en Montagetechniek zijn we gespecialiseerd in het monteren en onderhouden van hoogwaardige metaalproducten. Metalen constructies, machines of onderdelen</p>
      </div>
    </div>

    <div class="relative py-16 bg-white lg:py-6">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
        <div class="relative sm:py-16 lg:py-0">
          <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
            <div class="absolute inset-y-0 right-1/2 w-full rounded-r-3xl lg:right-72"></div>
            <svg class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
              <defs>
                <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
            </svg>
          </div>
          <div class="relative px-4 mx-auto max-w-md sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
            <div class="overflow-hidden relative pt-64 pb-10 rounded-sm shadow-sm rounded-xs">
              <img class="absolute inset-0 w-full h-full"
                   src="../assets/machine2.webp" width="300" height="220" alt="">
              <div class="relative px-8">
                <section class="mt-8">
                  <div class="relative text-lg font-medium text-white md:flex-grow">
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <div class="relative px-4 mx-auto max-w-md sm:max-w-3xl sm:px-6 lg:px-0">
          <div class="pt-12 sm:pt-16 lg:pt-20">
            <h2 class="mt-2 text-2xl font-semibold tracking-tight leading-8 text-gray-900 uppercase sm:text-2xl">Montage van RVS machines en metaalproducten</h2>

            <div class="mt-6 space-y-6 text-gray-600">
              <p class="text-md">
                Bij JC Las en Montagetechniek zijn we gespecialiseerd in het monteren en onderhouden van hoogwaardige metaalproducten. Metalen constructies, machines of onderdelen die gemonteerd moeten worden of hebben ze onderhoud nodig?
              </p>
              <p class="text-base leading-7">
                Zo produceren we niet alleen op maat, ook demonteren wij producten bij u op locatie. Zo houden we onze lijnen kort en hoeft u maar met één partij te werken. Dit bespaart u kosten en zo kunnen we garanderen dat u service en kwaliteit geleverd krijgt.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Tinybox
        v-model="index"
        :images="images"
        :loop="loopCheckbox"
        :no-thumbs="!thumbsCheckbox"
    ></Tinybox>

    <section class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-20">
      <ul role="list" class="grid grid-cols-2 md:grid-cols-4">
        <li v-for="(img, idx) in images" :key="idx" @click="index = idx" class="relative">
          <div class="block overflow-hidden w-full group aspect-w-10 aspect-h-7">
            <img :src="img.thumbnail" width="300" height="300" :alt="img.alt" class="object-cover pointer-events-none group-hover:opacity-75">
            <button type="button" class="absolute inset-0">
              <span class="sr-only">{{ img.caption }}</span>
            </button>
          </div>
        </li>
      </ul>
    </section>

    <div class="bg-white">
      <div class="py-12 px-4 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div class="lg:grid lg:grid-cols-1 lg:gap-8 lg:items-center">
          <div>
            <div class="mx-auto max-w-prose text-base lg:max-w-none">
              <h2 class="mt-2 text-2xl font-semibold tracking-tight leading-8 text-gray-900 uppercase sm:text-2xl">Rvs contructiewerken</h2>
            </div>
            <p class="mt-3 max-w-3xl text-gray-600 text-mx">
              Naast montage en onderhoud vervaardigen wij ook licht constructiewerk in de bouw.
              Denk hierbij aan het vervaardigen van frames, bordessen, trappen en leuningen in staal en RVS.
              Heeft u een eigen ontwerp? Ook dit rekenen we graag voor u na, om het vervolgens vakkundig te produceren.
              <br><br>
              Neem vrijblijvend contact met ons op om de mogelijkheden te bespreken.
              Wij bespreken vooraf met u technieken en werkwijze, zo houden we rekening met uw wensen en eisen.
              Hiermee bent u verzekerd van vakkundig advies en resultaat.
            </p>
            <div class="mt-8 sm:flex">
              <div class="rounded-md shadow">
                <router-link to="/contact" class="flex justify-center items-center py-3 px-5 w-full text-base font-medium rounded-sm border border-gray-800 hover:bg-gray-50">
                  Contact opnemen
                </router-link>
              </div>
            </div>
          </div>
<!--          <div class="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            <div class="flex col-span-1 justify-center py-8 px-8 bg-gray-50">
              <img class="max-h-32" src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg" alt="Workcation">
            </div>
            <div class="flex col-span-1 justify-center py-8 px-8 bg-gray-50">
              <img class="max-h-32" src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg" alt="Mirage">
            </div>
            <div class="flex col-span-1 justify-center py-8 px-8 bg-gray-50">
              <img class="max-h-32" src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg" alt="Tuple">
            </div>
            <div class="flex col-span-1 justify-center py-8 px-8 bg-gray-50">
              <img class="max-h-32" src="https://tailwindui.com/img/logos/laravel-logo-gray-400.svg" alt="Laravel">
            </div>
          </div>-->
        </div>
      </div>
    </div>


    <div class="overflow-hidden py-6 bg-white">
      <div class="px-4 mx-auto space-y-8 max-w-7xl sm:px-6 lg:px-8">
        <div class="mx-auto max-w-prose text-base lg:max-w-none">
          <h2 class="mt-2 text-2xl font-semibold tracking-tight leading-8 text-gray-900 uppercase sm:text-2xl">Reparatie en onderhoud van RVS machines en metaalproducten</h2>
        </div>
        <div class="relative z-10 mx-auto max-w-prose text-base lg:max-w-5xl lg:mx-0 lg:pr-72">
          <p class="text-gray-600 text-md">
            Ook voor het onderhouden van hoogwaardige metaalproducten en machines kunt u terecht bij JC Las en Montagetechniek. Onderhoud en reparatie van uw RVS metaalproducten en machines kunt u met een gerust hart aan ons over laten. Zowel periodiek onderhoud als herstelwerkzaamheden zijn geen enkel probleem.
          </p>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import Tinybox from "vue-tinybox";

export default {
  name       : "MontageOnderhoud",

  components: {
    Tinybox,
  },

  data() {
    return {
      images         : [
        {
          src       : '/assets/img/moo1_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/moo1_thumb.webp',
          caption   : ''
        },
        {
          src       : '/assets/img/moo2_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/moo2_thumb.webp',
          caption   : ''
        },
        {
          src       : '/assets/img/moo3_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/moo3_thumb.webp',
          caption   : ''
        },
        {
          src       : '/assets/img/moo4_img.webp',
          alt       : 'Image alttext',
          thumbnail : '/assets/img/moo4_thumb.webp',
          caption   : ''
        }
      ],
      index          : null,
      loopCheckbox   : false,
      thumbsCheckbox : true
    };
  },

  metaInfo : {
    title : 'Montage & Onderhoud',

    meta  : [
      {
        name    : 'description',
        content : 'Bij JC Las en Montagetechniek zijn we gespecialiseerd in het monteren en onderhouden van hoogwaardige metaalproducten. '
      }
    ]
  }
}
</script>

<style scoped>

</style>
